/**
 * @generated SignedSource<<842878d35c55dbf3fe5c36d08c0ec8c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadDateTableCell_SettingsUI_SubscriberUploadJob$data = {
  readonly batchId: number;
  readonly parameters: {
    readonly " $fragmentSpreads": FragmentRefs<"getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters">;
  };
  readonly subscriberUploadFile: {
    readonly timeUploaded: SerializedDateTime;
  };
  readonly " $fragmentSpreads": FragmentRefs<"getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob">;
  readonly " $fragmentType": "UploadDateTableCell_SettingsUI_SubscriberUploadJob";
};
export type UploadDateTableCell_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: UploadDateTableCell_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadDateTableCell_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "strategy",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "subscriptionTypes",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadDateTableCell_SettingsUI_SubscriberUploadJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "batchId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriberUploadFile",
      "kind": "LinkedField",
      "name": "subscriberUploadFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeUploaded",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriberUploadJobParameters",
      "kind": "LinkedField",
      "name": "parameters",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters",
          "selections": (v0/*: any*/),
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriptionFlow",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriberUploadJobParameters",
          "kind": "LinkedField",
          "name": "parameters",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "SubscriberUploadJob",
  "abstractKey": null
};
})();

(node as any).hash = "74d820f929de74fcc5ff2d4d14fd5ec2";

export default node;
