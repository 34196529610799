/**
 * @generated SignedSource<<43e87a9e10bcc1479ee4c0590a50e1a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SubscriberUploadJobStatus = "STATUS_ABANDONED" | "STATUS_COMPLETE" | "STATUS_INVALID" | "STATUS_IN_PROGRESS" | "STATUS_PROCESS" | "STATUS_UNKNOWN" | "STATUS_UPLOADED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UploadStatusTableCell_SettingsUI_SubscriberUploadJob$data = {
  readonly status: SubscriberUploadJobStatus;
  readonly subscriberUploadFile: {
    readonly lastUpdated: SerializedDateTime;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UploadStatusLabel_SettingsUI_SubscriberUploadJob">;
  readonly " $fragmentType": "UploadStatusTableCell_SettingsUI_SubscriberUploadJob";
};
export type UploadStatusTableCell_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: UploadStatusTableCell_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadStatusTableCell_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadStatusTableCell_SettingsUI_SubscriberUploadJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriberUploadFile",
      "kind": "LinkedField",
      "name": "subscriberUploadFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastUpdated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UploadStatusLabel_SettingsUI_SubscriberUploadJob"
    }
  ],
  "type": "SubscriberUploadJob",
  "abstractKey": null
};

(node as any).hash = "8154779bd24693265d7de91b964aae56";

export default node;
