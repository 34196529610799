import React, { ComponentProps, ComponentType } from 'react';
import {
  EntryPoint,
  PreloadedEntryPoint,
  loadEntryPoint,
  EntryPointContainer,
  EntryPointProps,
} from 'react-relay';
import { Params, LoaderFunction, useLoaderData } from 'react-router-dom';
import { ConcreteRequest, OperationType } from 'relay-runtime';
import { v4 as uuidv4 } from 'uuid';

import { DataBundle, getDataBundle } from './data-bundle';
import JSResource from './js-resource';
import { getRelayEnvironment } from './relay-environment';

type EntryPointComponentProps<Queries extends Record<string, OperationType>> = EntryPointProps<
  Queries,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, EntryPoint<any, any> | undefined>,
  {},
  {}
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BaseEntryPointComponent = ComponentType<EntryPointComponentProps<any>>;

type Loader<TModule extends BaseEntryPointComponent> = () => Promise<
  TModule | { readonly default: TModule }
>;

type GetQueriesArgs = {
  url: string;
  params: Params;
} & DataBundle;

type QueriesOf<Component extends BaseEntryPointComponent> = ComponentProps<Component>['queries'];

type RouteEntryPoint<Component extends BaseEntryPointComponent> = {
  component: Loader<Component>;
  getQueries: (args: GetQueriesArgs) => {
    [Q in keyof QueriesOf<Component>]: {
      parameters: ConcreteRequest;
      variables: QueriesOf<Component>[Q]['variables'];
    };
  };
};

function EntryPointRenderer() {
  const data = useLoaderData() as PreloadedEntryPoint<BaseEntryPointComponent>;

  return <EntryPointContainer entryPointReference={data} props={{}} />;
}

function prepareEntryPoint<C extends BaseEntryPointComponent>(
  entryPoint: RouteEntryPoint<C>
): EntryPoint<BaseEntryPointComponent, GetQueriesArgs> {
  return {
    root: JSResource(uuidv4(), entryPoint.component),
    getPreloadProps: (...args) => ({
      queries: { ...entryPoint.getQueries(...args) },
    }),
  };
}

function createEntryPoint<C extends BaseEntryPointComponent>(entryPoint: RouteEntryPoint<C>) {
  const preparedEntryPoint = prepareEntryPoint(entryPoint);
  const loader: LoaderFunction = (args) => {
    const res = loadEntryPoint({ getEnvironment: getRelayEnvironment }, preparedEntryPoint, {
      url: args.request.url,
      params: args.params,
      ...getDataBundle(),
    });

    return res;
  };

  const Component = EntryPointRenderer;

  return { loader, Component };
}

export { createEntryPoint };
export type { EntryPointComponentProps };
