import { createEntryPoint } from '@attentive/data-router';
import Query from './__generated__/SubscriberUploadListPage_SettingsUI_Query.graphql';

export const SubscriberUploadListEntryPoint = createEntryPoint({
  component: () => import('./SubscriberUploadListPage'),
  getQueries: ({ getCurrentCompanyId }) => ({
    query: {
      parameters: Query,
      variables: { companyId: getCurrentCompanyId() },
    },
  }),
});
