import { Environment } from 'react-relay';

let relayEnvironment: Environment | null = null;

function getRelayEnvironment(): Environment {
  if (!relayEnvironment) {
    throw new Error('Missing relay environment.');
  }

  return relayEnvironment;
}

function setRelayEnvironment(relayEnv: Environment) {
  relayEnvironment = relayEnv;
}

export { getRelayEnvironment, setRelayEnvironment };
