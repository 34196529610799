import React from 'react';
import { Table } from '@attentive/picnic';
import { FormattedSubscriberUpload } from '../../useSubscriptionUploads';
import { SubscriberUploadTableRow } from './components/SubscriberUploadTableRow';

interface OwnProps {
  items: FormattedSubscriberUpload[];
}

export const SubscriberUploadTable: React.VFC<OwnProps> = ({ items }) => {
  return (
    <Table columns={[2, 2, 2, 1, 1]} css={{ width: '100%' }}>
      <Table.Header>
        <Table.HeaderRow>
          <Table.HeaderCell>Upload date</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Uploaded by</Table.HeaderCell>
          <Table.HeaderCell align="right">Completed</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.HeaderRow>
      </Table.Header>
      <Table.Body>
        {items.map((item: FormattedSubscriberUpload) => {
          return <SubscriberUploadTableRow key={item.id} queryRef={item.queryRef} />;
        })}
      </Table.Body>
    </Table>
  );
};

export default SubscriberUploadTable;
