/**
 * @generated SignedSource<<f1c20af6ba250f2dda273a462102339d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob$data = {
  readonly id: string;
  readonly subscriberUploadFile: {
    readonly " $fragmentSpreads": FragmentRefs<"UploadedByTableCell_SettingsUI_SubscriberUploadFile">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CompletedCountTableCell_SettingsUI_SubscriberUploadJob" | "UploadDateTableCell_SettingsUI_SubscriberUploadJob" | "UploadStatusTableCell_SettingsUI_SubscriberUploadJob">;
  readonly " $fragmentType": "SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob";
};
export type SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UploadDateTableCell_SettingsUI_SubscriberUploadJob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UploadStatusTableCell_SettingsUI_SubscriberUploadJob"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompletedCountTableCell_SettingsUI_SubscriberUploadJob"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriberUploadFile",
      "kind": "LinkedField",
      "name": "subscriberUploadFile",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UploadedByTableCell_SettingsUI_SubscriberUploadFile"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SubscriberUploadJob",
  "abstractKey": null
};

(node as any).hash = "aebbade12c2f4c6b4ddb719f7c52210c";

export default node;
