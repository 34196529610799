import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { createRoutesFromElements } from './create-routes-from-elements';
import { DataBundle, setDataBundle } from './data-bundle';
import { setRelayEnvironment } from './relay-environment';

type RouterOpts = {
  basename?: string;
};

function createDataRouter<FeatureFlags extends string>(
  routes: React.ReactNode,
  dataBundle: DataBundle<FeatureFlags>,
  opts: RouterOpts
) {
  setRelayEnvironment(dataBundle.relayEnvironment);
  setDataBundle(dataBundle);

  return createBrowserRouter(createRoutesFromElements(routes, dataBundle), opts);
}

export { createDataRouter };
