import React from 'react';
import { RouteObject, Route } from 'react-router-dom';

import { DataBundle } from './data-bundle';
import { invariant } from './utils';

/**
 * For the feature flag type you likely want something backed by a query. For
 * usage within the scope of client-ui, this would be:
 * ```
 * import { CompanyFeatureFlagNames } from '@attentive/acore-utils';
 * ```
 */
export type RoutesFn<FeatureFlags extends string> = (
  dataBundle: DataBundle<FeatureFlags>
) => React.ReactNode;

function isRoutesFn(route: RoutesFn<never> | React.ReactNode): route is RoutesFn<never> {
  return route instanceof Function;
}

function prepareRoute(routesFn: RoutesFn<never>, dataBundle: DataBundle<never>): React.ReactNode {
  return routesFn(dataBundle);
}

/**
 *
 * Modified version of:
 * https://github.com/remix-run/react-router/blob/a23f017ec16b1eb3c622a5549645227066c49e3a/packages/react-router/lib/components.tsx#L624
 *
 * Necessary to enable working with functions as <Route> children, which is how
 * we pass along useful data to routes, eg: company id, feature flags, etc.
 */
function createRoutesFromElements(
  elements: React.ReactNode,
  dataBundle: DataBundle<never>,
  parentPath: number[] = []
): RouteObject[] {
  const routes: RouteObject[] = [];

  React.Children.forEach(elements, (element, index) => {
    if (!React.isValidElement(element)) {
      // Ignore non-elements. This allows people to more easily inline
      // conditionals in their route config.
      return;
    }

    const treePath = [...parentPath, index];

    if (element.type === React.Fragment) {
      // Transparently support React.Fragment and its children.
      routes.push(...createRoutesFromElements(element.props.children, dataBundle, treePath));
      return;
    }

    invariant(
      element.type === Route,
      `[${
        typeof element.type === 'string' ? element.type : element.type.name
      }] is not a <Route> component. All component children of <Routes> must be a <Route> or <React.Fragment>`
    );

    invariant(
      !element.props.index || !element.props.children,
      'An index route cannot have child routes.'
    );

    const route: RouteObject = {
      id: element.props.id || treePath.join('-'),
      caseSensitive: element.props.caseSensitive,
      element: element.props.element,
      Component: element.props.Component,
      index: element.props.index,
      path: element.props.path,
      loader: element.props.loader,
      action: element.props.action,
      errorElement: element.props.errorElement,
      ErrorBoundary: element.props.ErrorBoundary,
      hasErrorBoundary: element.props.ErrorBoundary != null || element.props.errorElement != null,
      shouldRevalidate: element.props.shouldRevalidate,
      handle: element.props.handle,
      lazy: element.props.lazy,
    };

    if (element.props.children) {
      // React router does not natively support passing functions as children,
      // which is what we consider a RoutesFn. We need to pass along our data
      // bundle and generate a valid set of react elements before returning to
      // the normal routes-from-elements flow.
      let children = element.props.children;
      if (isRoutesFn(children)) {
        children = prepareRoute(children, dataBundle);
      }

      route.children = createRoutesFromElements(children, dataBundle, treePath);
    }

    routes.push(route);
  });

  return routes;
}

export { createRoutesFromElements };
