/**
 * @generated SignedSource<<3602d87b62d89660f444387eaf710a75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriberUploadList_HistoryComponent_SettingsUI_Company$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_SubscriberUpload_SettingsUI_Company">;
  readonly " $fragmentType": "SubscriberUploadList_HistoryComponent_SettingsUI_Company";
};
export type SubscriberUploadList_HistoryComponent_SettingsUI_Company$key = {
  readonly " $data"?: SubscriberUploadList_HistoryComponent_SettingsUI_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriberUploadList_HistoryComponent_SettingsUI_Company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriberUploadList_HistoryComponent_SettingsUI_Company",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSubscriptionUploads_SubscriberUpload_SettingsUI_Company"
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "76c5c235739156dad940ac99830cd901";

export default node;
